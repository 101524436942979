import { createSlice } from "@reduxjs/toolkit";
import { toastText } from "utils/utils";
import { loginAction } from "redux/action/loginAction";
import { logoutAction } from "redux/action/logoutAction";

const initialState: any = {
  isLoading: false,
  error: null,
};

const AuthSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginAction.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(loginAction.fulfilled, (state:any, action: any) => {
      state.isLoading = false;
      action?.payload?.message, "success";
      state.data = action?.payload
    });
    builder.addCase(loginAction.rejected, (state, action: any) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    builder.addCase(logoutAction.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(logoutAction.fulfilled, (state, action) => {
      state.isLoading = false;
      localStorage.clear();
      toastText(action?.payload?.message, "success");
    });
    builder.addCase(logoutAction.rejected, (state, action: any) => {
      state.isLoading = false;
      state.error = action.payload;
      toastText(action?.payload?.message, "error");
    });
  },
});

export default AuthSlice;
