

export {
  integrationsAccountCards,
  channelTypeCards,
};

export const UUID = () => (Math.random() + 1).toString(36).substring(2);

export const AddUniqueID = (Arr: any[], lable: string) =>
  Arr.map((d: any) => {
    return {
      ...d,
      [lable]: UUID(),
    };
  });

const integrationsAccountCards = AddUniqueID(
  [
    {
      title: "Connect With Quickbooks Desktop",
      description:
        "is a robust accounting software for small and medium-sized businesses that provides comprehensive financial management tools and can be installed on a local computer.",
      buttonText: "Connect",
      logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/Accounting/quickbooks-desktop.png`,
      ghost: false,
      select: false,
      type: "Accounting",
      connect: false,
      titleKey: "QuickbooksDesktop",
      cssSpriteKey: "QuickbooksDesktop",
      DescriptionKey: "QuickBooks Desktop",
    },
  ],
  "id"
);


const channelTypeCards = [
  ...integrationsAccountCards
];
