import {
  Loader,
  SideDrawerWrapper,
  TableActionHeader,
} from "components/Global";
import ConfirmDelete from "components/Global/confirmDeleteModel";
import { roleColumns } from "constants/Data";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRoleActionTable,
  getRoleActionTable,
} from "redux/action/roleTableAction";
import { AppDispatch } from "redux/store";
import { AddSvg } from "utils/svgs";
import AddRoleBody from "./AddRoleBody";
import DynamicTable from "./Table";
import styles from "./index.module.scss";
import { userCheck } from "redux/slice/userCheckSlice";

// Creating the list of role table
const RoleTable = () => {
  const [drawerAnimation, setDrawerAnimation] = useState<boolean>(false);
  const [isSideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
  const [drawerInfo, setDrawerInfo] = useState({
    drawerTitle: "Add Role",
  });
  const [filteredData, setFilterData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editSelectedRole, setEditSelectedRole] = useState<any>();

  const dispatch = useDispatch<AppDispatch>();

  const { fistTimeFetchLoading } = useSelector(
    (state: any) => state?.roleTable
  );
  const roleFetch = useSelector((state: any) => state?.roleTable?.isLoading);

  const roleData = useSelector((state: any) => state?.roleTable?.data);

  // Formatting roleData and setting it in state
  useEffect(() => {
    if (!roleData) {
      dispatch(
        getRoleActionTable({
          url: ``,
        })
      ).then((res: any) => {
        if (res?.payload?.error?.status === 401) {
          const response = {
            isLoggedIn: true,
            isErrorMessage: res?.payload?.error?.message,
          };
          dispatch(userCheck(response));
        }
      });
    }

    const formattedRoles = roleData?.map((role: any) => ({
      id: role.id,
      name: role.roleName,
      description: role.roleDescription,
      status: role.status,
      isAdmin: role.isAdminRole,
    }));
    setFilterData(formattedRoles);
  }, [roleData]);

  // Remove from drawer handler
  const removeDrawerFromDom = () => {
    setSideDrawerOpen(false);
  };
  // For open the sideDrawer with animation
  const openDrawerHandler = () => {
    setDrawerAnimation(true);
    setSideDrawerOpen(true);
  };

  // For perform the close animation
  const closeDrawerByAnimation = () => {
    setDrawerAnimation(false);
    setEditSelectedRole(undefined);
  };

  //   For open the model
  const showModal = () => {
    setIsModalOpen(true);
  };

  // For change the data and title between components
  const setDrawerInfoHandler = (drawerTitle: any) => {
    setDrawerInfo({ drawerTitle });
  };

  //   For conform operation
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const deleteHandler = () => {
    if (editSelectedRole) {
      dispatch(deleteRoleActionTable({ roleId: editSelectedRole?.id })).then(
        (res: any) => {
          if (res?.payload?.error?.status === 401) {
            const response = {
              isLoggedIn: true,
              isErrorMessage: res?.payload?.error?.message,
            };
            dispatch(userCheck(response));
          }
          setEditSelectedRole(undefined);
          setIsModalOpen(false);
        }
      );
    }
  };

  //   For cancel operation
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={styles["role-table"]}>
        {!fistTimeFetchLoading ? (
          <>
            <TableActionHeader title={"Roles"}>
              <div className={styles["role-table__action"]}>
                {localStorage.getItem("companyId") !== "undefined" && (
                  <button
                    className={`btn-blue ${styles["role-table__action--button"]}`}
                    onClick={() => {
                      openDrawerHandler();
                      setDrawerInfoHandler("Add Role ");
                    }}
                  >
                    <AddSvg />
                    <p>Add Roles</p>
                  </button>
                )}
              </div>
            </TableActionHeader>
            <div>
              <DynamicTable
                roleDataSource={filteredData}
                roleColumns={roleColumns}
                showModal={showModal}
                openDrawerHandler={openDrawerHandler}
                setDrawerInfoHandler={setDrawerInfoHandler}
                setEditSelectedRole={setEditSelectedRole}
                isLoading={roleFetch}
              />
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
      <ConfirmDelete
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalOpen={isModalOpen}
        deleteHandler={deleteHandler}
      />
      {isSideDrawerOpen && (
        <SideDrawerWrapper
          isOpen={drawerAnimation}
          removeDrawerFromDom={removeDrawerFromDom}
          closeDrawerByAnimation={closeDrawerByAnimation}
          headerTitle={drawerInfo.drawerTitle}
          position="right"
          width="half"
        >
          <AddRoleBody
            closeDrawerByAnimation={closeDrawerByAnimation}
            editSelectedRole={editSelectedRole}
            setEditSelectedRole={setEditSelectedRole}
          />
        </SideDrawerWrapper>
      )}
    </>
  );
};

export default RoleTable;
