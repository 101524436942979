import UsersTable from "pages/settings/User";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getRoleActionTable } from "redux/action/roleTableAction";
import { clearRedux as clearReduxUser } from "redux/slice/userSlice";

export default function UsersPage() {
  const dispatch = useDispatch();

  // Clear Redux state when the component mounts
  useEffect(() => {
    dispatch(getRoleActionTable({ url: `` }) as any);
    dispatch(clearReduxUser());
  }, []);

  // JSX
  return (
    <div>
      <UsersTable />
    </div>
  );
}
