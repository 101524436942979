import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveConnectionAction } from "redux/action/activeConnectionsActions";
import { qbdItemsAction } from "redux/action/itemsAction";
import { AppDispatch } from "redux/store";
import DynamicTable from "./Table";
import NewCompany from "./NoCompany/newCompany";

const Home = () => {
  const dispatch = useDispatch<AppDispatch>();

  // Redux state
  const { items: ItemDataState, totalRecords } = useSelector(
    (state: any) => state?.qbdItems?.data || {}
  );

  const allActiveConnection = useSelector(
    (state: any) => state?.ActiveServices?.data
  );

  const [PageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemDataSource, setItemDataSource] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchFields, setSearchFields] = useState<string[]>([
    "name",
    "salesDescription",
  ]);

  // Handlers
  const paginationChangeHandler = (pageNo: number, pageSize: number) => {
    setCurrentPage(pageNo);
    setPageSize(pageSize);
  };

  const performSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
    setCurrentPage(1);
  };

  const resetButtonHandler = () => {
    setSearchValue("");
    setSearchFields(["name", "salesDescription"]);
    setCurrentPage(1);
  };

  const searchFieldsSelectHandler = (value: string[]) => {
    setSearchFields(value);
    setCurrentPage(1);
  };

  // Fetch data from Redux action
  useEffect(() => {
    const query = {
      pageSize: PageSize,
      pageNumber: currentPage,
      fields: searchFields,
      searchValue: searchValue,
    };

    const fetchData = async () => {
      try {
        await dispatch(getActiveConnectionAction());
        await dispatch(qbdItemsAction(query));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Fetch data on component mount
  }, [dispatch, PageSize, currentPage, searchFields, searchValue]);

  // Update itemDataSource whenever ItemDataState changes
  useEffect(() => {
    if (ItemDataState) {
      setItemDataSource(ItemDataState);
    }
  }, [ItemDataState]);

  return (
    <>
      <>
        {allActiveConnection &&
        allActiveConnection.length > 0 &&
        allActiveConnection[0].isActiveConnection ? (
          <div>
            <p
              style={{
                margin: "10px 0px",
                fontSize: "16px",
              }}
            >
              Item Inventory data for :&nbsp;
              <span
                style={{
                  fontWeight: "bolder",
                }}
              >
                {allActiveConnection &&
                  allActiveConnection.length > 0 &&
                  allActiveConnection[0].companyName}
              </span>
            </p>
            <DynamicTable
              itemDataSource={itemDataSource}
              paginationChangeHandler={paginationChangeHandler}
              PageSize={PageSize}
              currentPage={currentPage}
              totalRecords={totalRecords}
              performSearchHandler={performSearchHandler}
              searchValue={searchValue}
              searchFieldsSelectHandler={searchFieldsSelectHandler}
              resetButtonHandler={resetButtonHandler}
              searchFields={searchFields}
            />
          </div>
        ) : (
          <NewCompany />
        )}
      </>
    </>
  );
};

export default Home;
