import styles from "./index.module.scss";

// For the loader
const Loader = () => {
  return (
    <div className={styles["loader"]}>
      <img
        src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading-black.gif`}
        alt="loader"
        className={styles["loader--svg"]}
        crossOrigin={
          process.env.REACT_APP_ENV === "local" ? undefined : "anonymous"
        }
        height={60}
      />
    </div>
  );
};

export default Loader;
