import React, { FC } from "react";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Select, SelectProps } from "antd";
import styles from "./index.module.scss";
import { SearchAndFilterProps } from "./types";

const SearchAndFilter: FC<SearchAndFilterProps> = (props) => {
  const {
    performSearchHandler,
    searchValue,
    searchFieldsSelectHandler,
    resetButtonHandler,
    searchFields,
  } = props;

  const options: SelectProps["options"] = [
    { label: "Description", value: "salesDescription" },
    { label: "Name", value: "name" },
    { label: "Quantity On Site", value: "quantityOnHand" },
  ];
  return (
    <div className={styles["search-filter"]}>
      <div className={styles["search-filter__wrapper"]}>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={12} md={8} lg={8}>
            <Input
              className={styles["search-filter__search"]}
              placeholder="Search..."
              suffix={<SearchOutlined />}
              onChange={performSearchHandler}
              value={searchValue}
              size="large"
              disabled={searchFields.length === 0}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={8}>
            <Select
              className={styles["search-filter__select"]}
              mode="multiple"
              allowClear
              style={{ width: "100%" ,height:"40px"}}
              placeholder="Search on fields"
              onChange={searchFieldsSelectHandler}
              options={options}
              value={searchFields}
            />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8}>
            <Button
              type="primary"
              style={{ width: "50%" }}
              icon={<CloseCircleOutlined />}
              onClick={resetButtonHandler}
              block
            >
              Reset
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SearchAndFilter;
