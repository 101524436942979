import { Form, Input, Modal } from "antd";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { qbdUserAction } from "redux/action/qbdUserAction";
import { AppDispatch } from "redux/store";
import { CloseSvg } from "utils/svgs";
import { toastText } from "utils/utils";
import styles from "./index.module.scss";
import "./index.scss";
import { IntegrationModalProps } from "./types";
import { getActiveConnectionAction } from "redux/action/activeConnectionsActions";

// IntegrationModal popup
const IntegrationModal: FC<IntegrationModalProps> = (props) => {
  // Inits
  const {
    handleCancel,
    handleOk,
    isModalOpen,
    titleKey,
    formData,
    SetButtonLabel,
    ButtonLabel,

    uniqueUsername,
  } = props;

  const [form] = Form.useForm(); // Ant Design Form instance
  const [formValues, setFormValues] = useState({});

  const [isConnecting, setisConnecting] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleFormReset = () => {
    form.resetFields(); // Reset form fields
    setFormValues({}); // Reset controlled form values
  };

  // const profileData = useSelector((state: any) => state?.userProfile?.data);

  const handleFormSubmit = async (values: any) => {
    // SWITCH CASE FOR DIFFRENT DIFFRENT INTEGRATIONS

    if (!values.username && values.password) {
      values.username = uniqueUsername;
      const dataCredentials = {
        userName: values.username,
        password: values.password,
      };

      if (ButtonLabel === false) {
        setisConnecting(true);
        try {
          dispatch(qbdUserAction(dataCredentials) as any).then(
            async (res: any) => {
              if (res?.payload) {
              await dispatch(getActiveConnectionAction());

                toastText("Saved Credentials Successfully", "success");
                setisConnecting(false);
                await handleOk();
              } else {
                toastText("Invalid Credentials", "error");
              }
            }
          );
        } catch (error) {
          await handleCancel();
          toastText("Fail to Connect", "error");
        }
      }
    }
  };

  //   JSX
  return (
    <Modal
      className={`${styles["integration-model_width"]} integration-model_width `}
      title={
        <h3 className={styles["integration-model_title"]}>
          {`Provide credential to connect with ${titleKey}`}
        </h3>
      }
      open={isModalOpen}
      onOk={handleOk}
      onCancel={() => {
        handleCancel();
        SetButtonLabel(false);
        handleFormReset(); // Reset form fields and values when modal is closed
      }}
      centered={true}
      width={500}
      closeIcon={<CloseSvg />}
      footer={null}
    >
      <hr className={styles["hr_width"]} />

      <div className={styles["form_margin"]}>
        <Form
          form={form}
          initialValues={formValues}
          onFinish={handleFormSubmit}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {formData?.map((fieldData: any, index: any) => (
            <div className={styles["input-icon"]} key={index}>
              <div className={styles["input-icon__title"]}>
                {fieldData.svg && (
                  <div className={styles["input-icon__title--svg"]}>
                    {fieldData.svg}
                  </div>
                )}
                <label className={styles["input-icon__title--label"]}>
                  {fieldData.title}{" "}
                  {fieldData?.required && (
                    <span className="required-color">*</span>
                  )}
                </label>
              </div>
              <div className={styles["input-icon__form"]}>
                <Form.Item name={fieldData.name} rules={fieldData.rules}>
                  {fieldData.type === "text" ? (
                    <Input
                      placeholder={fieldData.placeHolder}
                      size="large"
                      className={styles["input-icon__form--input"]}
                      type={fieldData.type}
                      disabled={ButtonLabel}
                    />
                  ) : fieldData.type === "password" ? (
                    <Input.Password
                      placeholder={fieldData.placeHolder}
                      size="large"
                      className={styles["input-icon__form--input"]}
                      autoComplete="new-password"
                      disabled={ButtonLabel}
                    />
                  ) : fieldData.type === "qbd" ? (
                    <Input
                      placeholder={fieldData.placeHolder}
                      size="large"
                      className={styles["input-icon__form--input"]}
                      type={fieldData.type}
                      disabled={true}
                      defaultValue={uniqueUsername}
                    />
                  ) : null}
                </Form.Item>
              </div>
            </div>
          ))}
          {/* To add company in the NetSuite */}

          <hr className={styles["hr_width"]} />
          <div key={"wrapper"} className={styles["integration-model__button"]}>
            <button
              style={ButtonLabel ? { width: 120 } : undefined}
              type="submit"
              className={`${styles["integration-model__button--save"]} ${styles["integration-model__button--btn"]}`}
              disabled={isConnecting}
            >
              {isConnecting ? (
                <img
                  src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading-black.gif`}
                  height={40}
                  crossOrigin={
                    process.env.REACT_APP_ENV === "local"
                      ? undefined
                      : "anonymous"
                  }
                />
              ) : (
                (ButtonLabel && titleKey === "QuickbooksDesktop") || "Save"
              )}
            </button>
            <button
              type="button"
              onClick={() => {
                handleCancel();
                handleFormReset();
                SetButtonLabel(false); // Reset form fields and values when Cancel is clicked
              }}
              className={`${styles["integration-model__button--cancel"]} ${styles["integration-model__button--btn"]}`}
            >
              Cancel
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default IntegrationModal;
