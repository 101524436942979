import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ConfirmDelete from "components/Global/confirmDeleteModel";

import ConnectedTable from "./Table";
import styles from "./index.module.scss";
import {
  deleteConnection,
  getActiveConnectionAction,
} from "redux/action/activeConnectionsActions";

// Creating the list of user table
const ConnectedAppsTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const allActiveConnection = useSelector(
    (state: any) => state?.ActiveServices?.data
  );
  const dispatch = useDispatch();

  // Handle the pagination for the table
  const paginationChangeHandler = (pageNo: number) => {
    setCurrentPage(pageNo);
  };

  //   For conform operation
  const handleOk = () => {
    setIsModalOpen(false);
  };

  //   For cancel operation
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Delete
  const deleteHandler = async () => {
    await dispatch(deleteConnection({ selectedRecord }) as any);
    await dispatch(getActiveConnectionAction() as any);
    setIsModalOpen(false);
  };

  //   For open the model
  const showModal = () => {
    setIsModalOpen(true);
  };

  // JSX
  return (
    <>
      <div className={styles["integration-table"]}>
        <div>
          <div>
            <ConnectedTable
              integrationDataSource={allActiveConnection}
              paginationChangeHandler={paginationChangeHandler}
              currentPage={currentPage}
              totalRecords={10}
              showModal={showModal}
              setSelectedRecord={setSelectedRecord}
            />
          </div>
        </div>
      </div>
      <ConfirmDelete
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalOpen={isModalOpen}
        deleteHandler={deleteHandler}
      />
    </>
  );
};

export default ConnectedAppsTable;
