import { Space, Switch, Table, Typography } from "antd";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editRoleActionTable,
  getRoleActionTable,
} from "redux/action/roleTableAction";
import { AppDispatch } from "redux/store";

import {
  DeleteActionSvg,
  EditActionSvg,
  PermissionDetailsAdminSvg,
  SortSvgBottom,
  SortSvgTop,
} from "utils/svgs";
import styles from "./index.module.scss";
import "./index.scss";
import { DynamicTableProps } from "./types";

const { Text } = Typography;

const DynamicTable: FC<DynamicTableProps> = (props) => {
  const { Column } = Table;
  const {
    roleDataSource,
    showModal,
    openDrawerHandler,
    setDrawerInfoHandler,
    setEditSelectedRole,
  } = props;

  const dispatch = useDispatch<AppDispatch>();

  // Handle edit data action
  const editDataHandler = (roleObject: any) => {
    openDrawerHandler();
    setDrawerInfoHandler("Edit Role");
    setEditSelectedRole({
      ...roleObject,
      roleName: roleObject?.name,
      roleDescription: roleObject?.description,
    });
  };

  // Handle delete data action
  const deleteDataHandler = (roleObject: any) => {
    setEditSelectedRole({
      ...roleObject,
      roleName: roleObject?.name,
      roleDescription: roleObject?.description,
    });
  };

  // Handle status change
  const statusHandler = async (value: any, data: any) => {
    const finalData = {
      roleId: data?.id,
      status: value,
    };
    dispatch(editRoleActionTable(finalData)).then(async () => {
      const url = ``;
      await dispatch(
        getRoleActionTable({
          url,
        })
      );
    });
  };

  // For handle the table change click

  const loggedInUserRoleId = useSelector(
    (state: any) => state?.userProfile?.data?.companyRoles[0]?.roleid
  );

  // JSX
  return (
    <div className={styles["dynamic-table"]}>
      <Table
        dataSource={roleDataSource}
        // loading={isLoading}
        className="table-global"
        pagination={false}
        scroll={{ y: "calc(100vh - 350px)", x: "63vh" }}
      >
        <Column
          sortIcon={(data) => {
            return data.sortOrder === "ascend" ? (
              <SortSvgTop />
            ) : (
              <SortSvgBottom />
            );
          }}
          showSorterTooltip={{ title: "" }}
          defaultSortOrder={"ascend"}
          sorter={() => {
            return null as any;
          }}
          title={() => {
            return <>Role Name</>;
          }}
          dataIndex="name"
          key="name"
          width={"15%"}
          sortDirections={["ascend", "descend", "ascend"]}
          className="bg-white"
        />
        <Column
          title="Description"
          dataIndex="description"
          key="description"
          width={"25%"}
          className="bg-white"
        />
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          className="bg-white"
          width={"20%"}
          render={(value, rowData: any) => {
            return (
              <div className={styles["dynamic-table__status"]}>
                {rowData?.isAdmin || loggedInUserRoleId === rowData.id ? (
                  <>
                    <PermissionDetailsAdminSvg />
                    <Text className={styles["dynamic-table__status--admin"]}>
                      Enabled
                    </Text>
                  </>
                ) : (
                  <Switch
                    key={rowData.id}
                    checked={value}
                    onChange={(e) => statusHandler(e, rowData)}
                  ></Switch>
                )}
              </div>
            );
          }}
        />
        <Column
          title="Action"
          dataIndex="action"
          key="action"
          className="bg-white"
          width={"20%"}
          render={(value, data: any) => (
            <Space size={20}>
              {data.name === "Company Admin" ? (
                <>
                  <div
                    className={styles["dynamic-table__no-action"]}
                    style={{ marginRight: "2rem" }}
                  >
                    <EditActionSvg />
                  </div>
                  <div
                    className={styles["dynamic-table__no-action"]}
                    style={{ marginRight: "2rem" }}
                  >
                    <DeleteActionSvg />
                  </div>
                </>
              ) : (
                <>
                  {loggedInUserRoleId != data.id ? (
                    <div
                      className="cursor-pointer flex align-center justify-center"
                      onClick={() => editDataHandler(data)}
                      style={{ marginRight: "2rem" }}
                    >
                      <EditActionSvg />
                    </div>
                  ) : (
                    <div
                      className={styles["dynamic-table__no-action"]}
                      style={{ marginRight: "2rem" }}
                    >
                      <EditActionSvg />
                    </div>
                  )}
                  {loggedInUserRoleId != data.id ? (
                    <div
                      className="cursor-pointer flex align-center justify-center"
                      style={{ marginRight: "2rem" }}
                      onClick={() => {
                        deleteDataHandler(data);
                        showModal();
                      }}
                    >
                      <DeleteActionSvg />
                    </div>
                  ) : (
                    <div
                      className={styles["dynamic-table__no-action"]}
                      style={{ marginRight: "2rem" }}
                    >
                      <DeleteActionSvg />
                    </div>
                  )}
                </>
              )}
            </Space>
          )}
        />
      </Table>
    </div>
  );
};

export default DynamicTable;
