import { Table } from "antd";
import SearchAndFilter from "../SearchAndFilter/index";
import "./index.scss";
import { pageSizeOptionsPaging } from "utils/constant";
import { useSelector } from "react-redux";

const DynamicTable = (props: any) => {
  // Destructuring props
  const { Column } = Table;
  const {
    itemDataSource,
    performSearchHandler,
    searchValue,
    totalRecords,
    currentPage,
    PageSize,
    modifyPageSize,
    paginationChangeHandler,
    searchFieldsSelectHandler,
    resetButtonHandler,
    searchFields,
  } = props;

  const itemLoading = useSelector((state: any) => state?.qbdItems?.isLoading);

  // Render description with dash if empty
  const renderDescription = (text: string) => {
    return text ? text : "-";
  };

  return (
    <div className={"dynamic-table"}>
      <SearchAndFilter
        performSearchHandler={performSearchHandler}
        searchValue={searchValue}
        PageSize={PageSize}
        searchFieldsSelectHandler={searchFieldsSelectHandler}
        resetButtonHandler={resetButtonHandler}
        searchFields={searchFields}
      />

      <Table
        dataSource={itemDataSource}
        scroll={{ y: "calc(100vh - 350px)", x: "63vh" }}
        pagination={{
          total: totalRecords,
          current: currentPage,
          onChange: paginationChangeHandler,
          pageSize: PageSize,
          pageSizeOptions: pageSizeOptionsPaging,
          showSizeChanger: true,
          onShowSizeChange: modifyPageSize,
        }}
        loading={itemLoading}
        className="table-global"
      >
        <Column
          title="Name"
          dataIndex="name"
          key="name"
          className="bg-white"
        />
        <Column
          title="Description"
          dataIndex="salesDescription"
          key="salesDescription"
          className="bg-white"
          render={renderDescription}

        />
        <Column
          title="Quantity On Site"
          dataIndex="quantityOnHand"
          key="quantityOnHand"
          className="bg-white"
        />
      </Table>
    </div>
  );
};

export default DynamicTable;
