import { Col, Image, Row, Tooltip } from "antd";
import UserProfileModal from "components/Profile";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { logoutAction } from "redux/action/logoutAction";
import UserNameBox from "../UserNameBox";
import styles from "./index.module.scss";
import "./index.scss";
// import Cookies from "js-cookie";
import { LogoutOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";

// Website header
const Header = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isProfileModalOpen, setProfileModalOpen] = useState<boolean>(false);
  const [isLogoutBtnClick, setIsLogoutBtnClick] = useState(false);

  // const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: userData } = useSelector((state: any) => state?.userProfile);
  const profileData = useSelector((state: any) => state?.userProfile?.data);

  const logoutHandler = () => {
    setIsLogoutBtnClick(true);
    navigate("/login");
    Cookies.remove("accessToken");
  };

  // My Profile Handler
  const myProfileHandler = () => {
    setProfileModalOpen(true);
  };

  const profileCancel = () => {
    setProfileModalOpen(false);
  };

  // Handle navigation based on permissions
  const handleNavigatePermissionWise = () => {
    navigate("/settings/integrations/connectedApps");
  };

  return (
    <>
      <header className={styles["header"]}>
        <Row
          className={styles["header__wrapper"]}
          align={"middle"}
          justify={"space-between"}
        >
          <Col className={styles["header__details-left"]}>
            <div className={styles["header__details-left--logo"]}>
              <Image
                src={"/assets/3nStarLogo.png"}
                preview={false}
                alt="group"
              />
            </div>
          </Col>
          <Col className={styles["header__details-right"]}>
            {profileData?.companyRoles[0]?.role?.roleName === "Company Admin" && (
              <Tooltip placement="bottom" title={`Settings`}>
                <div
                  className={styles["header__details-right--settings"]}
                  onClick={() => {
                    handleNavigatePermissionWise();
                  }}
                >
                  <Image
                    src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/images/settings.png`}
                    preview={false}
                    alt="group"
                    crossOrigin={
                      process.env.REACT_APP_ENV === "local"
                        ? undefined
                        : "anonymous"
                    }
                  />
                </div>
              </Tooltip>
            )}

            <Tooltip placement="bottom" title={`Profile`}>
              <div
                className={styles["header__details-right--user"]}
                onClick={myProfileHandler}
              >
                <div className={styles["header__details-right--user-logo"]}>
                  <UserNameBox
                    name={`${profileData?.firstName} ${profileData?.lastName}`}
                    imageUrl={
                      userData?.profileImg &&
                      `${process.env.REACT_APP_AWS_BASE_URL}${userData?.profileImg}`
                    }
                  />
                </div>
                <div className={styles["header__details-right--user-details"]}>
                  <p className={styles["header__details-right--user-name"]}>
                    {profileData?.firstName} {profileData?.lastName}
                  </p>
                  <p className={styles["header__details-right--user-profile"]}>
                    My Profile
                  </p>
                </div>
              </div>
            </Tooltip>
            <Tooltip placement="bottom" title={`Log out`}>
              <div className={styles["header__details-right--user-logout"]}>
                <LogoutOutlined
                  style={{
                    cursor: isLogoutBtnClick ? "not-allowed" : "pointer",
                    pointerEvents: isLogoutBtnClick ? "none" : "auto",
                    fontSize: 23,
                    color: "#727272",
                  }}
                  onClick={logoutHandler}
                />
              </div>
            </Tooltip>
          </Col>
        </Row>
      </header>

      {isProfileModalOpen && (
        <UserProfileModal
          isProfileModalOpen={isProfileModalOpen}
          handleCancel={profileCancel}
        />
      )}
    </>
  );
};

export default Header;
