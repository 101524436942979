import { Layout, Menu } from "antd";
import { FC, useEffect } from "react";
import styles from "./index.module.scss";
import "./index.scss";
import { SidebarProps } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store";
import { getActiveConnectionAction } from "redux/action/activeConnectionsActions";

// Global sidebar
const IntegrationSidebar: FC<SidebarProps> = (props) => {
  // Inits
  const { Sider } = Layout;
  const { handleSidebar, items, selectedSidebar } = props;
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getActiveConnectionAction());
  }, []);

  const connectedAppsCount = useSelector(
    (state: any) => state?.ActiveServices?.data || []
  );

  // Get the count of active connections
  const activeConnectionsCount = connectedAppsCount.filter(
    (item: any) => item.isActiveConnection
  ).length;

  // Modify the menu items to include the connected apps count
  const modifiedItems = items?.map((item) => {
    if (item?.key === "connectedApps") {
      return {
        ...item,
        label: (
          <>
            Connected Apps{" "}
            <span style={{ color: "#153fcd" }}>({activeConnectionsCount})</span>
          </>
        ),
      };
    }

    return item;
  });

  // JSX
  return (
    <Sider
      style={{
        background: "#fff",
        overflow: "auto",
      }}
      className={`integration-sidebar ${styles.sidebar}`}
    >
      <div className={styles.sidebar__wrapper}>
        <Menu
          mode="inline"
          defaultSelectedKeys={[selectedSidebar]}
          selectedKeys={[selectedSidebar]}
          items={modifiedItems}
          onClick={handleSidebar}
          className="integration-menu-item"
          style={{
            background: "#fff",
            padding: 0,
          }}
        />
      </div>
    </Sider>
  );
};

export default IntegrationSidebar;
