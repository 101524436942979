// import ConnectedAppsTable from "components/settings/Integration/ConnectedApps";
import ConnectedAppsTable from "components/settings/Integration/ConnectedApps";
import NewConnectionApp from "components/settings/Integration/NewConnection";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveConnectionAction } from "redux/action/activeConnectionsActions";
import { AppDispatch } from "redux/store";

export default function Integrations() {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    // Fetch active connections when the component mounts
    dispatch(getActiveConnectionAction());
  }, []);

  const connectedAppsCount = useSelector(
    (state: any) => state?.ActiveServices?.data
  );

  return (
    // <div>
    //   {connectedAppsCount &&
    //     (Object.keys(connectedAppsCount).length > 0 ? (
    //       connectedAppsCount?.connectionDetails?.activeStatus ? (
    //         // Render the list of connected apps if activeStatus is true
    //         <ConnectedAppsTable />
    //       ) : (
    //         // Render the "New Connection" component if there are connected apps but activeStatus is false
    //         <NewConnectionApp />
    //       )
    //     ) : (
    //       // Render the "Loader" if connected apps are loading or there are no connected apps
    //       <div
    //         style={{
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           height: "63vh",
    //         }}
    //       >
    //         <img
    //           src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading-black.gif`}
    //           crossOrigin={
    //             process.env.REACT_APP_ENV === "local" ? undefined : "anonymous"
    //           }
    //           height={60}
    //         />
    //       </div>
    //     ))}
    // </div>
    <div>
      {connectedAppsCount?.length > 0 &&
      connectedAppsCount[0]?.isActiveConnection ? (
        // Render the list of connected apps if activeStatus is true
        <ConnectedAppsTable />
      ) : (
        <NewConnectionApp />
      )}
    </div>
  );
}
