import { LoginLayoutBody } from "components/Login";
import { FORMDATA } from "constants/Data";
import { LoginLayout } from "layouts";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postApi } from "redux/apis";
import { loginAction } from "redux/action/loginAction";
import { toastText } from "utils/utils";

const ForgotPassword = () => {
  // Initialize state
  // Initialize state to track loading and get a navigation function
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Handle form submission
  const onSubmit = async (values: any) => {
    setIsLoading(true);

    try {
      console.log("values", values);
      // Send a POST request to the server to handle password reset
      const response = await postApi("/auth/forgot-password", { email: values.email.toLowerCase() });
      const message = response?.data?.message;

      if (message) {
        // Show a success toast with the message
        toastText(message, "success");
      } else {
        throw new Error("No message in response.");
      }
      // Reset loading state and navigate back to the login page
      setIsLoading(false);
      navigate("/login");
    } catch (error: any) {
      setIsLoading(false);
      toastText(error?.response?.data?.message, "error");
    }
  };

  // JSX
  return (
    <LoginLayout>
      <LoginLayoutBody
        title="Forgot Password"
        formData={FORMDATA.forgotPassword}
        buttonTitle="Verify"
        description="Please Enter your Registered Email Address, we will send reset password link there."
        redirectUrl="/login"
        redirectText="Back to login"
        action={loginAction}
        onSubmit={onSubmit}
        isLoading={isLoading}
      ></LoginLayoutBody>
    </LoginLayout>
  );
};

export default ForgotPassword;
